.container {
  background-image: url("/public/assets/img/background.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -2px;
  padding-top: 1rem;
  width: 100%;
  height: 101vh;
}

.titleContainer {
  position: absolute;
  width: 80%;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 5%;
}

.title {
  font-family: "Bebas Neue";
  color: #fff;
  font-size: 7rem;
  line-height: 15%;
  font-weight: 500;
}

.sub-title {
  font-family: "Bebas Neue";
  font-weight: 500;
  color: #fff;
  font-size: 3.5rem;
  line-height: 0%;
}

@media (max-width: 900px) {
  .container {
    background-image: url("/public/assets/img/background-mobile.jpg");
  }

  .titleContainer {
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 0;
  }

  .title {
    font-family: "Bebas Neue";
    color: #fff;
    font-size: 5rem;
    line-height: 15%;
    font-weight: 500;
    text-align: center;
  }

  .sub-title {
    font-family: "Bebas Neue";
    font-weight: 500;
    color: #fff;
    font-size: 3rem;
    line-height: 0%;
    text-align: center;
  }
}
