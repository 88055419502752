.equipment-title-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.equipment-title {
  color: #2b69a4;
  font-family: Bebas Neue;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
}

.equipment-translation {
  color: #6c6c6c;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%;
}

.equipment-type-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-top: -2rem;
  z-index: 1000;
}

.equipment-type {
  color: #2b69a4;
  font-family: Bebas Neue;
  font-size: 10rem;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  margin-top: 0rem;
}

.equipment-type-mercedez {
  font-size: 8rem !important;
}

.equipment-type-hino {
  margin-top: 1.2rem !important;
}

.equipment-model {
  color: #6c6c6c;
  font-family: Bebas Neue;
  font-size: 5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 90%;
}

.equipment-img-container {
  background-size: cover;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.equipment-img {
  height: 100%;
}

@media (max-width: 900px) {
  .equipment-title-container {
    display: flex;
    flex-direction: column;
  }

  .equipment-title {
    font-size: 30px;
  }

  .equipment-translation {
    margin-top: -2rem;
    margin-bottom: 1rem;
  }

  .equipment-type-container {
    flex-direction: column;
    margin-top: 0rem;
  }

  .equipment-img-container {
    height: 30vh;
  }

  .equipment-type {
    font-size: 5rem;
  }

  .equipment-model {
    font-size: 3rem;
    margin-top: -5rem;
  }
}
