.footer-container {
  background-color: #1C4E9E;
  height: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  color: #fff;
  overflow: hidden;
}

.footer-info-container {
  width: 15%;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.contact-container {
  font-family: Jost;
  line-height: 20%;
}

.contact-title {
  text-transform: uppercase;
}

@media (max-width: 900px) {
    .footer-container {
        background-position: top;
        height: 50vh;
        flex-direction: column;
        justify-content: center;
        padding-top: 0rem;
      }
      .footer-info-container {
        width: 100%;
        margin: 1rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: fit-content;
      }

      .contact-container {
        font-family: Jost;
        line-height: 0%;
      }
}