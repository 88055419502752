.logo {
  width: 23px;
  height: 46px;
}

.logoContainerHorizontal {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.logoContainerVertical {
  display: flex;
  align-items: center;
  flex-direction: column;
}
