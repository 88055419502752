.mision-title {
  font-family: "Bebas Neue";
  font-size: 32px;
  font-weight: 500;
  line-height: 0%;
  color: #000;
}

.mision-subtitle {
  color: #1e1e1e;
  text-align: center;
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
}

.mision-translation {
  color: #6c6c6c;
  text-align: center;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.values-img {
    max-width: 100%;
    /* max-height: 200px; */
}
