.logo-cliente {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.title-client-container {
  padding: 2rem 0 5rem 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
}

.logo-cliente-1 {
  background-image: url("/public/assets/logos/7.png");
  width: 250px;
  height: 54px;
}

.logo-cliente-2 {
  background-image: url("/public/assets/logos/12.png");
  width: 110px;
  height: 110px;
}

.logo-cliente-3 {
  background-image: url("/public/assets/logos/9.png");
  width: 150px;
  height: 110px;
}

.logo-cliente-4 {
  background-image: url("/public/assets/logos/5.png");
  width: 250px;
  height: 54px;
}

.logo-cliente-5 {
  background-image: url("/public/assets/logos/2.png");
  width: 250px;
  height: 100px;
}

.logo-cliente-6 {
  background-image: url("/public/assets/logos/4.png");
  width: 120px;
  height: 110px;
}

.logo-cliente-7 {
  background-image: url("/public/assets/logos/1.png");
  width: 200px;
  height: 74px;
}

.logo-cliente-8 {
  background-image: url("/public/assets/logos/3.png");
  width: 250px;
  height: 100px;
}

.logo-cliente-9 {
  background-image: url("/public/assets/logos/6.png");
  width: 250px;
  height: 54px;
}

.logo-cliente-10 {
  background-image: url("/public/assets/logos/8.png");
  width: 250px;
  height: 130px;
}

.logo-cliente-11 {
  background-image: url("/public/assets/logos/10.png");
  width: 300px;
  height: 200px;
}

.logo-cliente-12 {
  background-image: url("/public/assets/logos/11.png");
  width: 200px;
  height: 100px;
}

.logo-cliente-drillar {
  background-image: url("/public/assets/logos/drillar.png");
  width: 200px;
  height: 100px;
}

@media (min-width: 800px) and (max-width: 1200px) {
  .logo-cliente-1 {
    background-image: url("/public/assets/logos/7.png");
    width: 150px;
    height: 32px;
  }

  .logo-cliente-2 {
    background-image: url("/public/assets/logos/12.png");
    width: 70px;
    height: 70px;
  }

  .logo-cliente-3 {
    background-image: url("/public/assets/logos/9.png");
    width: 100px;
    height: 70px;
  }

  .logo-cliente-4 {
    background-image: url("/public/assets/logos/5.png");
    width: 200px;
    height: 14px;
  }

  .logo-cliente-5 {
    background-image: url("/public/assets/logos/2.png");
    width: 250px;
    height: 100px;
  }

  .logo-cliente-6 {
    background-image: url("/public/assets/logos/4.png");
    width: 100px;
    height: 75px;
  }

  .logo-cliente-7 {
    background-image: url("/public/assets/logos/1.png");
    width: 100px;
    height: 35px;
  }

  .logo-cliente-8 {
    background-image: url("/public/assets/logos/3.png");
    width: 250px;
    height: 100px;
  }

  .logo-cliente-9 {
    background-image: url("/public/assets/logos/6.png");
    width: 250px;
    height: 54px;
  }

  .logo-cliente-10 {
    background-image: url("/public/assets/logos/8.png");
    width: 250px;
    height: 130px;
  }

  .logo-cliente-11 {
    background-image: url("/public/assets/logos/10.png");
    width: 200px;
    height: 100px;
  }

  .logo-cliente-12 {
    background-image: url("/public/assets/logos/11.png");
    width: 200px;
    height: 80px;
  }

  .logo-cliente-drillar {
    background-image: url("/public/assets/logos/drillar.png");
    width: 180px;
    height: 80px;
  }
}

@media (max-width: 800px) {
  .title-client-container {
    padding: 2rem 0 2rem 0;
  }

  .logo-cliente-1 {
    background-image: url("/public/assets/logos/7.png");
    width: 140px;
    height: 30px;
  }

  .logo-cliente-2 {
    background-image: url("/public/assets/logos/12.png");
    width: 70px;
    height: 70px;
  }

  .logo-cliente-3 {
    background-image: url("/public/assets/logos/9.png");
    width: 80px;
    height: 70px;
  }

  .logo-cliente-4 {
    background-image: url("/public/assets/logos/5.png");
    width: 170px;
    height: 14px;
  }

  .logo-cliente-5 {
    background-image: url("/public/assets/logos/2.png");
    width: 180px;
    height: 100px;
  }

  .logo-cliente-6 {
    background-image: url("/public/assets/logos/4.png");
    width: 90px;
    height: 75px;
  }

  .logo-cliente-7 {
    background-image: url("/public/assets/logos/1.png");
    width: 150px;
    height: 54px;
  }

  .logo-cliente-8 {
    background-image: url("/public/assets/logos/3.png");
    width: 250px;
    height: 100px;
  }

  .logo-cliente-9 {
    background-image: url("/public/assets/logos/6.png");
    width: 250px;
    height: 54px;
  }

  .logo-cliente-10 {
    background-image: url("/public/assets/logos/8.png");
    width: 250px;
    height: 130px;
  }

  .logo-cliente-11 {
    background-image: url("/public/assets/logos/10.png");
    width: 200px;
    height: 100px;
  }

  .logo-cliente-12 {
    background-image: url("/public/assets/logos/11.png");
    width: 200px;
    height: 60px;
  }

  .logo-cliente-drillar {
    background-image: url("/public/assets/logos/drillar.png");
    width: 120px;
    height: 60px;
  }
}
