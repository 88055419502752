.navbarContainer {
  background-color: rgba(43, 105, 164, .56);
  width: 80%;
  height: 10px;
  margin-left: 5%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 2rem 4rem 2rem 4rem;
}

.navbarTitle {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 12 !important;
  font-weight: bold;
  text-transform: uppercase;
}

.navbarSubtitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-width: 550px;
}

.navbarSubTitle {
  font-family: "Roboto", sans-serif;
  font-size: 12 !important;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .navbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem 2rem 2rem 2rem;
  }
}