.service-number {
  font-family: Jost;
  font-size: 7rem;
  font-weight: 500;
  line-height: 0%;
  color: #000;
}

.service-title {
  color: #1e1e1e;
  font-family: Jost;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
}

.service-translation {
  color: #6c6c6c;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%;
  margin-top: -2rem;
}

.text-carousel-container {
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40%;
  height: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.img-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  height: auto;
}

.img-carousel {
  max-width: 100%;
  height: 100%;
}

@media (max-width: 900px) {
  .text-carousel-container {
    background-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .service-number {
    text-align: center;
    font-size: 5rem;
  }

  .service-title {
    text-align: center;
    font-size: 2.5rem;
  }

  .service-translation {
    text-align: center;
  }
}
