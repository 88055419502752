.who-we-are-container {
  background-color: #f1f1f1;
  width: 100%;
  /* height: 100vh; */
}

.who-we-are-container-titles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.who-we-are-title {
  font-family: "Bebas Neue";
  font-size: 32px;
  font-weight: 500;
  line-height: 0%;
  color: #2b69a4;
}

.who-we-are-subtitle {
  color: #1e1e1e;
  text-align: center;
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
}

.who-we-are-translation {
  color: #6c6c6c;
  text-align: center;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top:0;
  left: 0;
}

.who-we-are-history-first-column {
  color: #1e1e1e;
  text-align: center;
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  text-align: right;
}

.who-we-are-history-translation-first-column {
  color: #6c6c6c;
  text-align: right;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%; 
}

.who-we-are-history-second-column {
  color: #000;
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
}

.who-we-are-history-translation-second-column {
  color: #6c6c6c;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%; 
}

@media (max-width: 900px) {
  .who-we-are-history-first-column {
    text-align: left;
  }
  .who-we-are-history-translation-first-column {
    text-align: left;
  }
  .who-we-are-history-second-column {
    text-align: left;
    margin-top: -.5rem;
  }
  .who-we-are-history-translation-second-column {
    text-align: left;
    margin-top: -.5rem;
  }
}